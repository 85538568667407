
/* Bootstrap override */
:root {

  /* Colors */
  --inv-color-primary: #000C66; /* #1e55a5; */
  --inv-color-secondary: #ed5267;
  --inv-color-background: white;
  --inv-color-background-message: #ffffaf;
  --inv-color-light: #d8d8d8;
  --inv-color-support: #bcbcbc;
  --bs-dark-rgb: var(--inv-color-primary);
  --bs-light-gray: #dfdfdf;
  --bs-light-green: #d7ffad;
  --inv-color-border: #e2e2e2;

  /* Font */
  --inv-h1: 2.5rem;
  --inv-h2: 2rem;
  --inv-h3: 1.5rem;  
  --inv-font-size: .8rem;
  --inv-font-size-title: var(--inv-h1);
  --inv-font-size-subtitle: 1.3rem;
  --inv-font-size-form: .9rem;
  --inv-font-color: #405267;

/* Margins & Paddings */
 --inv-padding: .5rem;
 --inv-double-padding: 1rem;
 --inv-panel-padding: 1.2rem;

  --inv-shadow: none; /*0 10px 15px 0 rgba(0, 0, 0, 0.1), 0 10px 20px 0 rgba(0, 0, 0, 0.1); */
  --inv-inner-shadow: none; /* inset -10px -10px 25px 10px rgba(0, 0, 0, 0.1); */

  /* Animation */
  --inv-animation-time: 0s

}

/* Animations */
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(180deg);}
}

@keyframes slideInFromLeft {
  from {
      transform: translateX(-100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideOutToLeft {
  from {
      transform: translateX(0);
      opacity: 1;
  }
  to {
      transform: translateX(-100%);
      opacity: 0;
      position: absolute; left: -9999px;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

body {
  margin: 0;
  font-size: var(--inv-font-size);
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;  
  color: var(--inv-font-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-image: url('../public/images/backgrounds/background.svg'); */
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Headers */
h1 {
  font-size: var(--inv-h1);
  font-weight: 400;
}

h2 {
  font-size: var(--inv-h2);
  font-weight: 400;
}

h3 {
  font-size: var(--inv-h3);
  font-weight: 400;
}

h1, h2, h3 {
	margin-bottom:1.2rem;
 letter-spacing: -0.1rem;
}

/* List */
ul, li {
  list-style-type: none;
  padding: 0 .5rem;
}

/* Layouyt */
.right {
  float: right;
}

.layout-container {
  display: flex;
  flex-direction: column;
}

.layout-body {
  display: flex;
  height: calc(100vh - 4rem); /* subtracting header height */
}

header {
  padding: var(--inv-padding);
  border-bottom: 1px solid var(--inv-color-border);
}

/* Menu */
.menu {
  border-right: 1px solid var(--inv-color-border);
}

.menu-open, .menu-closed {
  overflow-y: auto;
  width: 250px;
  padding: var(--inv-double-padding) var(--inv-double-padding);
}

.menu-closed {
  width: 0;
}

.menu-button {
  display: none; /* Initially hide the button on larger screens */
}

.menu-items {
  opacity: .8;
  font-weight: 500;
}

.menu-items ul li {
  padding: .2rem 0rem;
}

.menu-items ul a {
  text-decoration: none;
  color: var(--inv-secondary-color);
}

.menu-items ul a:hover, .menu-items ul a:hover {
    text-decoration: underline;
    color: var(--inv-secondary-color);
    opacity: 1;
}

div.content {
  flex: 1;
  overflow-y: auto;
  padding: 0 1rem 2rem;
}

div .logo img {
  padding: 0px 5px;
  width: 150px;
  cursor: pointer;
  opacity: .8;
}

div .logo img:hover {
  opacity: 1;
}



/* Remove default browser outline */
*:focus {
  outline: none;
}


/* Buttons */
button.btn {
  margin-top: .6em;
	padding: .6rem;
	font-weight: bold;
  min-width: 200px;
  border: none;
  border-radius: var(--bs-border-radius);
}

.btn-primary {
  color: #fff;
  background-color: var(--inv-color-secondary);
  opacity: 0.9;
}

.btn-primary:hover {
  opacity: 1;
}

.btn-primary:active {
  opacity: 1;
  background-color: var(--inv-color-light);
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.bg-dark {
  background-color: var(--bs-dark-rgb);
}


/* Panel */
div.panel-container {
  padding: 1rem 2rem;
  width: fit-content;
  width: auto;

  animation: fadeIn var(--inv-animation-time) forwards;
}

div.panel-container div.panel-header {
  padding-bottom: var(--inv-padding);
}

div.panel-container div.panel-header h1,
div.panel-container div.panel-header h2 {
  margin: unset;
  margin-bottom: -5px;
  padding: unset;
}

div.panel-container div.panel-header h3 {
  margin: unset;
  padding: unset;
}

div.panel-container div.panel-header div.image-header {
  /* position: relative; */
}

div.panel-container div.image-header img {
  width: 140px;
}

div.panel-container div.panel-header h3 {
  font-weight: 300;
  font-size: var(--inv-font-size-subtitle);
}

div.panel-container div.panel-header div.image-header {
  position: inherit;
  text-align: center;
  top: -20px;
  width: 100%;
}

div.panel-container div.panel-body {
  padding: var(--inv-panel-padding);
  border: 1px solid var(--inv-color-border);
  border-radius: var(--bs-border-radius);  
}

/*  Pages */
div.page-container {
  max-width: 1680px;
  margin: 0 auto;

  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  align-items: start; */
}


/* Form */
form * {
  border-radius: var(--bs-border-radius);
}
form {
  text-align: left;
}

form .form-group {
  margin-bottom: .5rem;
}

form .form-group-btn {
  justify-content: right;
  display: flex;
  margin: var(--inv-double-padding) 0px 0xp;
}

form .form-control {
  padding: 0.2rem 0.5rem;
  font-weight: 300;
}

form .form-control:focus {
  box-shadow: none;
}

form label {
  font-size: var(--inv-font-size-form);
  /* Overlay input */
  margin-bottom: -5px;
  vertical-align: bottom;
}

form p.field-group {
  border-bottom: solid 1px var(--bs-light-gray);
  border-radius: 0;
  margin: 1rem 0 .5rem;
  font-weight: 500;
  color: var(--inv-color-primary);
  opacity: 0.45;
}

form input {
  font-size: var(--inv-font-size-form) !important;
}

form input.number {
  text-align: right;
}

form select {
  color: var(--bs-white);
  border: 1px solid var(--inv-color-support);
  background-color: var(--inv-color-support);
}

form .input-side-button {
  min-width: 80px;
}

/* Help icon */
form span.help-icon i {
  color: #725FEB;
  opacity: .45;
}

form span.help-icon i:hover {
  color: var(--bs-blue);
  opacity: .45;
}

div.help-icon-message {
  z-index: 1;
  box-shadow: var(--inv-shadow);
  background-color: var(--inv-color-background-message);
}


/* Table */
div.table {
  border-radius: var(--bs-border-radius);
  box-shadow: 0 2px 10px 0 rgba(0, 12, 102, 0.1);
  word-wrap: break-word;
}

div.table .row:first-child  {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

div.table .row:last-child  {
  border-bottom-left-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

div.table div {
  padding: 0.2rem;
}

div.table div.number {
  text-align: right;
}

div.table .row:nth-child(even) {
  background-color: var(--bs-light-gray);
}

div.table .row:nth-child(odd) {
  background-color: var(--bs-white);
}

div.table .row:first-child {
  color: var(--bs-white);
  font-weight: 600;
  text-align: center;
  background-color: #725FEB;
}

div.table .col.center {
  text-align: center;
}

/* Errors */
div.error-validation {
  display: flex;
  margin: 1rem 0;
  padding: .5rem;
  border-radius: var(--bs-border-radius);
  box-shadow: 0 2px 20px 0px rgba(102, 77, 3, .2);
  border: none;
}

div.error-validation div.alert-warning-icon {
  opacity: .3;
  align-self: center;
}

div.error-validation ul {
  word-break: break-word;
}

div.error-validation li {
  margin: .5rem;
  list-style-type: none; /* Removes default bullet points */
  margin-left: 1.5em;    /* Adjusts space for the custom bullet */
  position: relative;    /* Makes it possible to position the ::before content relative to the li */
}

div.error-validation li::before {
  content: '►';          /* The custom character */
  position: absolute;
  left: -1em;          /* Adjust this value as needed to position your custom bullet */
  opacity: .25;
}


/* Tutorials */
div.more_about {
  margin-top: 2rem;
  font-size: medium;
  font-weight: 300;
  font-style: italic;
  color: var(--bs-gray);
}

div.tutorial-container h1, h2, h3 {
  margin-top: 2rem;
}

div.tutorial-container b {
  font-weight: 600;
  color: var(--bs-gray);
  /* background-color: var(--bs-light-gray); */
}

div.tutorial-container u {
  font-weight: 600;
  color: var(--bs-gray);
  text-decoration: underline;
  /* background-color: var(--bs-light-gray); */
}

.break-words {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto; 
}


/* Media query for screens smaller than a certain width */
@media (max-width: 768px) {

  div.content.menuVisible {
    display: none;
  }

  header button.menu-button {
    margin-top: 0;
    padding: 0;
    font-weight: unset;
    min-width: unset
  }

  header button.menu-button i {
    color: var(--inv-color-secondary);
    animation: spin var(--inv-animation-time) linear .5;
  }

  aside.menu {
    width: 100%;
    animation: slideInFromLeft var(--inv-animation-time) forwards;
  }

  aside.menu.hide {
    animation: slideOutToLeft var(--inv-animation-time) forwards;
  }

  .menu-button {
    display: block; /* Show the button on smaller screens */
    cursor: pointer;
  }

  .menu-button.active + .menu-items {
    display: block; /* Show the menu when the button is clicked (active state) */
  }


  
  /* Form */
  form div.form-group.row {
    display: block;
  }

  form div.form-group.row div {
    width: 100%;
  }

  form div.form-group.row label {
    margin-bottom: .1rem;
  }

  form div.form-group.form-group-btn button {
    width: 100%;
  }

  div.table {
    font-size: small;
  }

  /* Errors */
  div.error-validation {
    display: block;
  }

  div.error-validation div.alert-warning-icon {
    text-align: center;
  }

}
