.result-compound-interest-panel {
  display: flex;
  grid-column-gap: 20px;
  justify-content: center;
}

.result-compound-interest-panel>div {
  width: max-content;
  padding: 1rem 1rem;
  margin: 1rem 0 1rem;
  border-radius: var(--bs-border-radius);
  box-shadow: var(--inv-inner-shadow);
  text-align: center;
}

.result-compound-interest-panel>div.principal {
  background-color: #2089C9;
}

.result-compound-interest-panel>div.interest {
  background-color: #1FBF78;
}

.result-compound-interest-panel>div.total-amount {
  background-color: var(--bs-gray-600);
}


.result-compound-interest-panel p {
  margin-bottom: 0;
  font-weight: bold;
  color: var(--bs-light);
}

.result-compound-interest-panel .value {
  font-size: x-large;
}

/* Media query for screens smaller than a certain width */
@media (max-width: 768px) {

  div.result-compound-interest-panel {
    display: block;
  }

  div.result-compound-interest-panel div {
    min-width: unset;
    width: 100%;
  }

  .result-compound-interest-panel .value {
    font-size: large;
  }

}